import axios from "axios";
import Cookies from "js-cookie";

// AUTH STUFF
const auth = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {
    RESPONSE(state, data) {
      state.response = data;
    },
    ERR_MSG(state, error) {
      state.errMsg = error;
    },
  },
  actions: {
    login(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let url = '';
        if (data.sso) {
          url = '/api/sso/login';
        } else {
          url = '/api/login'
        }

        axios
          .post(`${env}${url}`, data)
          .then((res) => {
            let data = res.data;
            Cookies.set("token", data.token, { expires: 7 });
            Cookies.set("id", data.data.id, { expires: 7 });
            Cookies.set("role", data.data.access_right, { expires: 7 });
            Cookies.set("dataAuth", JSON.stringify(data), { expires: 7 });
            context.commit("RESPONSE", data);
            resolve(res.data)
          })
          .catch((error) => {
            console.log(error);
            context.commit("ERR_MSG", error.response.data);
            reject(error.response.data);
          });
      });
    },
    
  },
};

export default auth;
